import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Corner from '~/components/elements/svg/Corner'
import type { Maybe, Component_Header_MenuHeader_Submenu } from '~/graphql/types'

interface HeaderSubMenuProps {
  fields: Maybe<Component_Header_MenuHeader_Submenu>[]
}

export default function HeaderSubMenu({ fields }: HeaderSubMenuProps) {
  return (
    <div className="pt-3">
      <ul className="relative flex w-fit sm:py-2 sm:px-3 lg:bg-ubo-taillow lg:p-4 lg:shadow-xl">
        <div className="hidden sm:block">
          <Corner className="absolute right-2 top-2 h-5 w-5 children-path:fill-white" />
          <Corner className="absolute left-2 top-2 h-5 w-5 -rotate-90 children-path:fill-white" />
          <Corner className="absolute right-2 bottom-2 h-5 w-5 rotate-90 children-path:fill-white" />
          <Corner className="absolute left-2 bottom-2 h-5 w-5 rotate-180 children-path:fill-white" />
        </div>
        {fields.map((f, index: number) => (
          <li className="list-none py-2 sm:px-2" key={index}>
            <LosseLink to={f?.link?.url || '/'} className="group block px-2 py-2 sm:px-4">
              <LossePlaatjie loading="eager" src={f?.icon} className="mx-auto h-8 w-8 sm:h-10 sm:w-10" />
              <span className="mt-4 block w-full text-center font-september text-lg font-bold uppercase leading-5 text-white group-hover:underline lg:text-xl">
                {f?.link?.title}
              </span>
            </LosseLink>
          </li>
        ))}
      </ul>
    </div>
  )
}
