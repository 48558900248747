/* eslint-disable @typescript-eslint/no-explicit-any */
import { LosseLink, useLoaderData, useLocation } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import clsx from 'clsx'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'
import Corner from '~/components/elements/svg/Corner'
import { useState } from 'react'
import useLayout from '~/hooks/useLayout'
import type { PageLoaderData } from '~/templates/page'
import type { Component_Header_MenuHeader_Submenu, Maybe } from '~/graphql/types'
import LangSwitch from '~/components/elements/LangSwitch'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const { stickyMenuOpen, navigationBgColor } = useLayout()
  const [currentIndex, setCurrentIndex] = useState(-1)
  const { pathname } = useLocation()

  const nicePath = pathname.split('/')[1]

  function isPartiallyActive(url: string) {
    if (url === '/' && !nicePath) {
      return true
    }

    if (url === `/${nicePath}/`) {
      return true
    }

    return false
  }

  const pathsWithoutNav = ['grow-business-leadgeneratie']

  return (
    <>
      {header?.menuHeader?.map((edge, linkIndex) => {
        if (!edge?.link?.url) return null

        const open = currentIndex === linkIndex

        return (
          <li
            onMouseEnter={() => setCurrentIndex(linkIndex)}
            onMouseLeave={() => setCurrentIndex(-1)}
            className={clsx(
              stickyMenuOpen && 'font-september font-black uppercase text-ubo-delibird',
              !stickyMenuOpen && navigationBgColor !== 'transparent' && 'text-ubo-whiscash',
              navigationBgColor === 'transparent' && 'text-ubo-delibird',
              pathsWithoutNav.includes(pathname.replace(/\//g, '')) && !stickyMenuOpen && 'pointer-events-none !opacity-0',
              'relative xl:mx-1 2xl:mx-2',
              !stickyMenuOpen && edge.showInDropdownMenu && 'hidden'
            )}
            key={`${edge?.link?.title}`}
          >
            <AnimatePresence>
              {((open && !stickyMenuOpen) || (isPartiallyActive(edge?.link?.url) && !stickyMenuOpen)) && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <Corners />
                </motion.div>
              )}
            </AnimatePresence>
            <div className="relative flex flex-wrap items-center">
              <MenuItem navigationBgColor={navigationBgColor} link={edge?.link} active={isPartiallyActive(edge.link.url)} />
            </div>

            {edge?.submenu && (
              <motion.div
                className="relative w-full lg:absolute"
                animate={open ? 'open' : 'closed'}
                variants={{
                  closed: {
                    opacity: 0,
                    scale: 0.9
                  },
                  open: {
                    opacity: 1,
                    scale: 1
                  }
                }}
              >
                <HeaderSubMenu fields={edge.submenu as Maybe<Component_Header_MenuHeader_Submenu>[]} />
              </motion.div>
            )}
          </li>
        )
      })}
      <li
        className={clsx(
          ((!stickyMenuOpen && navigationBgColor !== 'transparent') || (navigationBgColor === 'transparent' && !stickyMenuOpen)) &&
            'ml-4 2xl:ml-16',
          !stickyMenuOpen && 'mr-4 2xl:mr-12',
          stickyMenuOpen && 'mt-6 flex justify-center'
        )}
      >
        <LangSwitch type={stickyMenuOpen ? 'alt' : 'normal'} />
      </li>
      {!stickyMenuOpen && (
        <li
          className={clsx(
            stickyMenuOpen && 'font-september text-4xl uppercase text-ubo-delibird md:text-5xl xl:mx-1 2xl:mx-2',
            !stickyMenuOpen && navigationBgColor !== 'transparent' && 'text-xl text-ubo-whiscash ',
            navigationBgColor === 'transparent' && !stickyMenuOpen && 'text-xl text-ubo-delibird',
            pathsWithoutNav.includes(pathname.replace(/\//g, '')) && 'pointer-events-none !opacity-0'
          )}
        >
          <div className="relative flex flex-wrap items-center lg:flex-nowrap">
            <LosseLink
              className={clsx(
                stickyMenuOpen
                  ? 'link-underline link-underline-blue relative mb-4 flex w-full items-center px-2 text-4xl font-black uppercase text-ubo-delibird md:text-5xl lg:mb-7 lg:px-0'
                  : 'btn--dark--filled max-2xl:!px-3 max-2xl:!py-1',
                navigationBgColor === 'transparent' && 'hover:border-ubo-delibird hover:text-ubo-delibird'
              )}
              to={header.menuCta?.url}
            >
              {header.menuCta?.title}
            </LosseLink>
            <LosseLink
              className={clsx(
                stickyMenuOpen
                  ? 'link-underline link-underline-blue relative flex w-full items-center px-2 text-4xl font-black uppercase text-ubo-delibird md:text-5xl lg:px-0'
                  : 'btn--support max-2xl:!px-3 max-2xl:!py-1 lg:ml-2',
                navigationBgColor === 'transparent' && 'hover:border-ubo-delibird hover:text-ubo-delibird'
              )}
              to={header?.menuCtaSecond?.url}
            >
              {header?.menuCtaSecond?.title}
            </LosseLink>
          </div>
        </li>
      )}
    </>
  )
}

function MenuItem({ link, navigationBgColor, active }: { link: any; navigationBgColor: string; active: boolean }) {
  const [isHovered, setHovered] = useState(false)
  const { stickyMenuOpen } = useLayout()
  const { rest } = useLoaderData<PageLoaderData>()
  const loc = useLocation()

  return (
    <>
      {link.url === loc.pathname ? (
        <div
          className={clsx(
            stickyMenuOpen
              ? 'link-underline link-underline-blue flex w-full items-center text-4xl md:text-5xl'
              : 'text-xl lg:text-base 2xl:text-lg',
            navigationBgColor !== 'transparent' && 'hover:text-ubo-taillow',
            navigationBgColor !== 'transparent' && active && 'text-ubo-taillow',
            'relative inline-flex px-2 font-september'
          )}
        >
          <AnimatePresence>
            {((stickyMenuOpen && isHovered) || (stickyMenuOpen && active)) && (
              <motion.div
                initial={{ opacity: 0, marginLeft: -20 }}
                animate={{ opacity: 1, marginLeft: 0 }}
                exit={{ opacity: 0, marginLeft: -60 }}
                className="mr-2"
              >
                <motion.svg
                  className="relative top-[2px]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="33"
                  fill="none"
                  viewBox="0 0 50 33"
                >
                  <mask id="mask0_1984_20424" style={{ maskType: 'alpha' }} width="50" height="33" x="0" y="0" maskUnits="userSpaceOnUse">
                    <path fill="#070744" d="M0 0H50V33H0z"></path>
                  </mask>
                  <g fill="#070744" mask="url(#mask0_1984_20424)">
                    <path d="M-60.283-51.376h-28.4l68.216 68.215-68.216 68.216h28.4L7.933 16.839l-68.216-68.215z"></path>
                    <path d="M-18.506-51.376h-28.4L21.309 16.84l-68.215 68.216h28.4l68.215-68.216-68.215-68.215z"></path>
                  </g>
                </motion.svg>
              </motion.div>
            )}
          </AnimatePresence>
          <span>{link?.title}</span>
          {link?.title?.toLowerCase() === 'cases' && (
            <span
              className={clsx(
                stickyMenuOpen ? '-mt-6 text-sm lg:-mt-8 lg:h-7 lg:w-7 lg:text-base' : '-mt-2 text-xs',
                'ml-1 flex h-5 w-5 items-center justify-center rounded-full bg-ubo-taillow font-medium text-white'
              )}
            >
              {rest?.cases?.pageInfo.total}
            </span>
          )}
        </div>
      ) : (
        <LosseLink
          className={clsx(
            stickyMenuOpen
              ? 'link-underline link-underline-blue flex w-full items-center text-4xl md:text-5xl'
              : 'text-xl lg:text-base 2xl:text-lg',
            navigationBgColor !== 'transparent' && 'hover:text-ubo-taillow',
            navigationBgColor !== 'transparent' && active && 'text-ubo-taillow',
            'relative inline-flex px-2 font-september'
          )}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onTouchMove={() => setHovered(true)}
          onTouchEnd={() => setHovered(false)}
          to={link?.url || '/'}
          target={link.target || '_self'}
        >
          <AnimatePresence>
            {((stickyMenuOpen && isHovered) || (stickyMenuOpen && active)) && (
              <motion.div
                initial={{ opacity: 0, marginLeft: -20 }}
                animate={{ opacity: 1, marginLeft: 0 }}
                exit={{ opacity: 0, marginLeft: -60 }}
                className="mr-2"
              >
                <motion.svg
                  className="relative top-[2px]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="33"
                  fill="none"
                  viewBox="0 0 50 33"
                >
                  <mask id="mask0_1984_20424" style={{ maskType: 'alpha' }} width="50" height="33" x="0" y="0" maskUnits="userSpaceOnUse">
                    <path fill="#070744" d="M0 0H50V33H0z"></path>
                  </mask>
                  <g fill="#070744" mask="url(#mask0_1984_20424)">
                    <path d="M-60.283-51.376h-28.4l68.216 68.215-68.216 68.216h28.4L7.933 16.839l-68.216-68.215z"></path>
                    <path d="M-18.506-51.376h-28.4L21.309 16.84l-68.215 68.216h28.4l68.215-68.216-68.215-68.215z"></path>
                  </g>
                </motion.svg>
              </motion.div>
            )}
          </AnimatePresence>
          <span>{link?.title}</span>
          {link?.title?.toLowerCase() === 'cases' && (
            <span
              className={clsx(
                stickyMenuOpen ? '-mt-6 text-sm lg:-mt-8 lg:h-7 lg:w-7 lg:text-base' : '-mt-2 text-xs',
                'ml-1 flex h-5 w-5 items-center justify-center rounded-full bg-ubo-taillow font-medium text-white'
              )}
            >
              {rest?.cases?.pageInfo.total}
            </span>
          )}
        </LosseLink>
      )}
    </>
  )
}

function Corners() {
  return (
    <div className="absolute top-[0.125rem] h-full w-full">
      <div className="relative">
        <motion.div className="absolute -right-2 -top-3">
          <Corner />
        </motion.div>
        <div className="absolute -top-3 left-2">
          <Corner className="-rotate-90" />
        </div>
        <div className="absolute -right-2 top-2">
          <Corner className="rotate-90" />
        </div>
      </div>
    </div>
  )
}
